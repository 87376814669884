<template>
  <div class="Entrepot">
    <BlockTitle
      :cnName="titleConfig.cnName"
      :enName="titleConfig.enName"
    />
    <template v-for="item in entrepotList">
      <div :key="item.imgUrl" class="content_center_medium Entrepot-container">
        <div class="Entrepot-container-left"><img :src="item.businessImgUrl"></div>
        <div class="Entrepot-container-right">
          <div class="Entrepot-container-right-top" v-lazy:background-image="project_bg">
            <ImageText
              :isIcon="false"
              :isImage="false"
              :imageTextOptions="item"
            />
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
  import entrepot from '@/assets/images/business/entrepot.png';
  import project_bg from '@/assets/images/business/project_bg.png';
  import business_1 from '@/assets/images/business/business-1.jpg';
  import business_2 from '@/assets/images/business/business-2.jpg';
  import business_3 from '@/assets/images/business/business-3.jpg';
  import business_4 from '@/assets/images/business/business-4.jpg';
  import business_5 from '@/assets/images/business/business-5.jpg';
  import business_6 from '@/assets/images/business/business-6.png';
  import business_7 from '@/assets/images/business/business-7.jpg';
  import business_8 from '@/assets/images/business/business-8.jpg';
  import ImageText from '@/components/ImageText.vue';
  export default {
    name: 'Entrepot',
    components: {
      ImageText,
    },
    data() {
      return {
        titleConfig: {
          cnName: '产品服务',
          enName: 'PRODUCT SERVICE',
        },
        entrepot,
        project_bg,
        entrepotList: [
          {
            title: '综服平台',
            text: [
              '政府主导、市场运作',
              '海丰电商与各地政府国有平台合资搭建跨境电商综服平台，打通跨境电商“关、汇、税、商、物、金融”间的信息壁垒，全面支持1210、9610、9710、9810等业务，提供跨境电商整体解决方案，同时导入政府扶持政策，实现跨境电商的产业聚集和新业态。'
            ],
            businessImgUrl: business_6,
          },
          {
            title: '跨境全链路服务',
            text: [
              '操作灵活，定制化方案',
              '支持日本、韩国、菲律宾、印尼、泰国、马来西亚等7东国整柜订舱、电商拼箱服务，全国上门装柜，海内外两端实力清关，实力大卖供应链优化服务的首选。'
            ],
            businessImgUrl: business_8,
          },
          {
            title: '进出口集货',
            text: [
              '海丰电商在日照设有集货仓（中心仓、前置海外仓），将华南（广深）、华东（江浙沪）经山东出口韩国的货物、海空联运的货物在日照完成集货、出货。'
            ],
            businessImgUrl: business_1,
          },
          {
            title: '海外仓服务',
            text: [
              '智能操作系统，高效订单处理',
              '总面积约为3万平的海外专业电商仓库，优选三方仓可支持跨境店本土发货模式，提供成熟且完善的仓储管理，自研数字化电商系统可实时同步订单及库存信息，更多海外仓服务持续上线中。'
            ],
            businessImgUrl: business_2,
          },
          {
            title: '仓储服务',
            text: [
              '可为客户提供仓储、物流、清关及金融等一站式服务；其采用区港联动模式（无需电子关锁），在给客户提供便利的同时，也可降低客户的短驳运输成本，让客户得到真正实惠。'
            ],
            businessImgUrl: business_3,
          },
          {
            title: '物流服务',
            text: [
              '综服平台结合建设“货代SAAS内部管理平台”及“航运智能协同平台”，以平台一体化形式，协同运营解决地区物流痛点；为企业提供网络运营平台，进行电商客户信息备案管理、商品备案管理、电商订单信息、支付信息、物流信息等信息的收集处理管理服务，并通过系统提交海关平台完成通关。接受企业委托，为服务企业提供出口货物空运、海运及陆路运输。'
            ],
            businessImgUrl: business_4,
          },
          {
            title: '关务服务',
            text: [
              '海丰电商由专业报关人员从事进出口报关业务：从接单到预录、审单、放行智能一键申报，实现人工智能生成报关单、报关智能审单、报关数据自动校验等功能，速度快，规避了错单及查验风险。'
            ],
            businessImgUrl: business_5,
          },
          {
            title: '专线海运服务',
            text: [
              '时效承诺，经济实惠',
              '海丰电商海运明星产品，自有航线实力保证，覆盖日韩、东南亚等航线，服务稳定、专线直航、优先靠泊、保舱保柜保时效，是电商卖家、平台快速补货的安心之选。'
            ],
            businessImgUrl: business_7,
          },
        ],
      }
    },
  }
</script>

<style lang="scss" scoped>
.Entrepot{
  margin: 100px auto;
  &-container{
    display: flex;
    margin-top: 60px;
    &-left{
      width: 550px;
      height: 340px;
      margin-right: 30px;
      img{
        width: 100%;
        height: 100%;
      }
    }
    &-right{
      flex: 1;
      &-top{
        width: 100%;
        height: 340px;
      }
      &-bottom{
        display: flex;
        justify-content: space-between;
        height: 300px;
        margin-top: 30px;
        &-left,
        &-right{
          flex: 1;
          box-sizing: border-box;
          background-color: #F8F8F8;
          ::v-deep(.ImageText){
            &-left{
              .title{
                margin-bottom: 20px;
                color: #33333B;
              }
            }
          }
        }
        &-left{
          margin-right: 30px;
        }
      }
    }
  }
}
</style>