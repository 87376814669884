<template>
  <div class="Business">
    <SubTopBanner :topBannerUrl="top_banner">
      <HeaderNav :navIndex="3" />
    </SubTopBanner>
    <Entrepot id="anchor_a" />
    <!-- <LogisticsPark id="anchor_b" /> -->
    <Footer />
  </div>
</template>

<script>
  import HeaderNav from '@/components/HeaderNav.vue';
  import Entrepot from './Entrepot.vue';
  // import LogisticsPark from './LogisticsPark.vue';
  import top_banner from '@/assets/images/business/top_banner.png';
  export default {
    name: 'Business',
    components: {
      HeaderNav,
      Entrepot,
      // LogisticsPark,
    },
    data() {
      return {
        top_banner,
      }
    },
    mounted () {
      this.getBanner();
    },
    methods: {
      async getBanner() {
        const res = await this.$request.get(`/ad/data/business_banner`);
        if(res.data.length) {
          this.top_banner = res.data[0].image;
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
.Business{
  
}
</style>